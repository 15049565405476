<template>
    <section class="contact-us-area section-padding bg-color-dark img-cover">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title text-center">
                        <span class="water-text">{{ data.sectionTitleWaterText }}</span>
                        <h2 class="title">{{ data.sectionTitle }}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="contact-info text-center">
                        <div class="contact-info__list address">
                            <i class="fa fa-home"></i>
                            <p>Toulon, France</p>
                        </div>
                        <div class="contact-info__list email">
                            <i class="fa fa-envelope"></i>
                            <a href="mailto:contact@dylanleflour.fr">contact@dylanleflour.fr</a>
                        </div>
                        <div class="contact-info__list phone">
                            <i class="fa fa-phone"></i>
                            <a href="tel:0786423929">07 86 42 39 29</a>
                        </div>
                    </div>
                </div>
<!--                <div class="col-lg-8 offset-lg-2">-->
<!--                    <form class="contact-form">-->
<!--                        <div class="row">-->
<!--                            <div class="col-12">-->
<!--                                <input name="name" type="text" placeholder="Votre Nom">-->
<!--                            </div>-->
<!--                            <div class="col-12">-->
<!--                                <input name="subject" type="email" placeholder="Votre Sujet">-->
<!--                            </div>-->
<!--                            <div class="col-12">-->
<!--                                <input name="email" type="email" placeholder="Votre Email">-->
<!--                            </div>-->
<!--                            <div class="col-12">-->
<!--                                <textarea name="message" placeholder="Votre Message"></textarea>-->
<!--                            </div>-->
<!--                            <div class="col-12">-->
<!--                                <button class="btn btn-default">Envoyer un message</button>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->
            </div>
        </div>
    </section>
</template>

<script>
    import data from '../data/contact.json';
    export default {
        data () {
            return {
                data
            }
        }
    };
</script>