<template>
    <div class="google-map-area section" id="support">
        <GmapMap
            :center="{lat:43.123076, lng:5.949125}"
            :zoom="15"
            map-type-id="terrain"
        >
        </GmapMap>
    </div>
</template>

<script>
    import Vue from 'vue';
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAiVYS1s4qUZFulKc4y9umIaw4Adt9Xufo',
        },
    
    })
    export default {
        name: 'GoogleMap',
    }
</script>
